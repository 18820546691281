import router from './routers'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({ showSpinner: false })
const whiteList = ['/loginm']
router.beforeEach((to, from, next) => {
  if (getToken('TokenKey')) {
    if (to.path === '/loginm') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next({ path: '/loginm' })
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
