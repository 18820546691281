import request from '@/utils/request'

// 获取用户信息
export function UserInfo (params) {
  return request({
    url: '/web/getUserInfo',
    method: 'get',
    params
  })
}

export function login (data) {
  return request({
    url: '/web/login',
    method: 'post',
    data
  })
}

export function hbgAddr (data) {
  return request({
    url: '/web/hbgAddr',
    method: 'post',
    data
  })
}

export function ListData (params) {
  return request({
    url: '/web/hbg/withdraw/list',
    method: 'get',
    params
  })
}

// 新增加地址

export function withdraw (data) {
  return request({
    url: '/web/hbg/withdraw/add',
    method: 'post',
    data
  })
}

// 获取验证码
export function sendMsg (params) {
  return request({
    url: '/web/sendMsg',
    method: 'get',
    params
  })
}

export function getCodeImg () {
  return request({
    url: 'auth/code',
    method: 'get'
  })
}
