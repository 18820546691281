import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/loginm',
    name: 'loginm',
    component: (resolve) => require(['@/views/login'], resolve)
  },
  {
    path: '/',
    name: 'Home',
    component: (resolve) => require(['@/views/Home'], resolve)
  },

  {
    path: '*',
    name: 'dianot',
    component: (resolve) => require(['@/views/404'], resolve)
  }

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
