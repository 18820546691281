import Cookies from 'js-cookie'
import Config from '@/settings'

// const TokenKey = Config.TokenKey

export function getToken (username) {
  return Cookies.get(username, { expires: Config.tokenCookieExpires })
}

export function setToken (username, token) {
  // if (rememberMe) {
  return Cookies.set(username, token, { expires: Config.tokenCookieExpires })
  // } else return Cookies.set(TokenKey, token)
}

export function removeToken (username) {
  return Cookies.remove(username)
}
