import { login } from '@/api/login'
import { getToken, setToken } from '@/utils/auth'
// , removeToken

const user = {
  state: {

    token: getToken('TokenKey'),

    user: getToken('user')

  },
  mutations: {

    SET_TOKEN: (state, token) => {
      state.token = token
    },

    SET_USER: (state, res) => {
      state.user = res
    },
    LoginTui: (state) => {
      state.token = null
      state.user = null
    }
  },

  actions: {
    // 登录接口
    Login ({ commit }, userInfo) {
      login(userInfo).then(res => {
        setToken('TokenKey', res.token)
        const userObj = JSON.stringify(res)
        setToken('user', userObj)
        commit('SET_TOKEN', res.token)
        commit('SET_USER', res)
      }).catch(() => {

      })
    }

  }

}

export default user
