import axios from 'axios'
import { getToken } from '@/utils/auth'
import { Notification } from 'element-ui'
import router from '@/router/routers'

const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  timeout: 24000 // 请求超时时间
})
// request拦截器(请求拦截器)
service.interceptors.request.use(
  config => {
    if (getToken('TokenKey')) {
      config.headers.Authorization = getToken('TokenKey') // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    if (error?.response?.status === 401) {
      Promise.reject(error)
    }
  }
)

// 响应拦截器
service.interceptors.response.use(response => {
  if (response.data instanceof Blob) return response.data
  return response.data
}, error => {
  let code = 0
  try {
    // eslint-disable-next-line no-unused-vars
    code = error.response.data.status
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Notification.error({
        title: '网络请求超时',
        duration: 5000
      })
      return Promise.reject(error)
    }
  }

  if (code) {
    if (code === 400) {
      if (error.response.data.message === 'token错误') {
        router.push({ path: '/' })
      } else {
        Notification.error({
          title: error.response.data.message,
          duration: 5000
        })
      }
    }
  }
})

export default service
